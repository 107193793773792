import React from 'react';
import cx from 'classnames';

import { ThemeColors } from '@/@types/theme';

type BadgeColors = Extract<ThemeColors, 'primary' | 'green'>;

export type BadgeProps = {
  color?: BadgeColors;
  className?: string;
};

const Badge: React.FC<BadgeProps> = ({ children, color, className }) => {
  return (
    <span
      className={cx(
        'px-3 text-caption',
        `border-2 border-${color} rounded-full text-${color} bg-${color}-lighter`,
        className
      )}
    >
      {children}
    </span>
  );
};

Badge.defaultProps = {
  color: 'primary'
};

export default Badge;
