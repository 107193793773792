import React from 'react';
import cx from 'classnames';

import Image from 'next/image';

import Badge from '@/components/shared/Badge';
import Typography from '@/components/shared/Typography';

export type StepIllustrationProps = {
  className?: string;
  data: {
    step: number;
    title: string;
    description: string;
    illustration: {
      src: string;
      width: number;
      height: number;
      alt?: string;
    };
  };
};

const StepIllustration: React.FC<StepIllustrationProps> = ({
  className,
  data
}) => {
  const { illustration } = data;

  return (
    <div className={cx('flex flex-col items-center w-74', className)}>
      <Badge>{`STEP ${data.step}`}</Badge>
      <Typography className="my-2" type="h3">
        {data.title}
      </Typography>
      <Typography className="text-center mb-2" type="b3">
        {data.description}
      </Typography>

      <Image {...illustration} />
    </div>
  );
};

export default StepIllustration;
