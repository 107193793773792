import { useViewport } from '@/hooks/useViewport';
import router from 'next/router';
import React from 'react';
import Banner from '../shared/Banner';
import Button from '../shared/Button';
import Typography from '../shared/Typography';

const KSMakesKidsSmile: React.FC = () => {
  const { isMobile } = useViewport();

  return (
    <Banner
      height={580} // 580
      className=" bg-right-top md:bg-center"
      backgroundImage="url('/images/banner/darius.jpg')"
    >
      <div
        className="relative w-full h-full"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.4)'
        }}
      >
        <div className="absolute w-full top-8">
          <div className="max-w-lg _ml-auto mr-auto ml-4 xl:ml-40">
            <div className="ml-4 mr-4">
              <Typography
                className="text-white text-h1 font-serif font-bold text-center md:text-left"
                style={{
                  fontSize: isMobile ? 27 : 32,
                  fontFamily: 'Libre Baskerville',
                  color: 'white'
                }}
              >
                Keepsakes that make
              </Typography>
              <Typography
                className="text-white text-h1 font-serif font-bold text-center md:text-left"
                style={{
                  fontSize: isMobile ? 27 : 32,
                  fontFamily: 'Libre Baskerville',
                  color: 'white'
                }}
              >
                kids smile
              </Typography>
              <div className="w-full">
                <div className="w-256 ml-auto mr-auto md:ml-0">
                  <Button
                    className="w-full h-14 mt-96 md:mt-72"
                    color="white"
                    textColor="primary"
                    onClick={() => {
                      router.push('/shop');
                    }}
                    aria-label="Buy Now"
                  >
                    <div className="flex justify-center items-center">
                      <span
                        className="font-extrabold text-14 ml-2 font-sans"
                        style={{ fontFamily: 'Nunito', fontWeight: 800 }}
                      >
                        Buy Now
                      </span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default KSMakesKidsSmile;
