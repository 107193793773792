import React from 'react';
import cx from 'classnames';

import { Carousel } from 'react-responsive-carousel';

import { Icon } from '@/components/shared/Icon';
import ExploreOurStoriesCard from '@/components/ExploreOurStoriesCarousel/ExploreOurStoriesCard';
import { RoundButton } from '@/components/shared/Button';

import styles from './styles.module.css';
import Banner from '../shared/Banner';
import { useViewport } from '@/hooks/useViewport';
import ThreeDBook from '../shared/ThreeDBook';
import { RootStateOrAny, useSelector } from 'react-redux';
import { BookData } from '@/services/data/book';

type ControlsProps = {
  onPrev: () => void;
  onNext: () => void;
  className?: string;
};

const Controls: React.FC<ControlsProps> = ({ onNext, onPrev, className }) => {
  return (
    <div className={cx('w-full m-auto', className)}>
      <RoundButton
        className="float-left"
        onClick={onPrev}
        aria-label="previous"
      >
        <Icon icon="arrow-left" color="white" />
      </RoundButton>

      <RoundButton className="float-right" onClick={onNext} aria-label="next">
        <Icon icon="arrow-right" color="white" />
      </RoundButton>
    </div>
  );
};

export type ExploreOurStoriesCarouselClassProps = {
  autoPlay?: boolean;
  className?: string;
  list: BookData[];
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type StateProps = {
  currentSlide: number;
  autoPlay: boolean;
  list: ExploreOurStoriesCarouselClassProps['list'];
  width: number;
  percentage: number;
};

const ExploreOurStoriesCarousel: React.FC<ExploreOurStoriesCarouselClassProps> =
  propsComponent => {
    const viewport = useViewport();

    const { booksBuildKeys, characterPreview } = useSelector(
      (state: RootStateOrAny) => state
    );

    class ExternalControlledCarousel extends React.Component<
      ExploreOurStoriesCarouselClassProps,
      StateProps
    > {
      constructor(props: ExploreOurStoriesCarouselClassProps) {
        super(props);

        this.state = {
          currentSlide: 0,
          autoPlay: !!props.autoPlay,
          list: props.list,
          width: 0,
          percentage: 0
        };
      }

      next = () => {
        this.setState(state => ({
          currentSlide: state.currentSlide + 1
        }));
      };

      prev = () => {
        this.setState(state => ({
          currentSlide: state.currentSlide - 1
        }));
      };

      changeAutoPlay = () => {
        this.setState(state => ({
          autoPlay: !state.autoPlay
        }));
      };

      updateCurrentSlide = (index: number) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
          this.setState({
            currentSlide: index
          });
        }
      };

      render() {
        return (
          <div
            className={cx(
              'bg-lightBlue overflow-hidden pb-10 pt-0 mt-0 relative w-full',
              this.props.className,
              styles.container
            )}
            style={{
              height: 800
            }}
          >
            <div className="absolute w-full left-0 -top-6">
              <Carousel
                centerMode={true}
                infiniteLoop
                autoPlay={!!this.props.autoPlay}
                selectedItem={this.state.currentSlide}
                showStatus={false}
                onChange={this.updateCurrentSlide}
                interval={10000}
                centerSlidePercentage={100}
                renderIndicator={() => null}
                showIndicators={false}
                useKeyboardArrows
                showThumbs={false}
                showArrows={false}
              >
                {this.props.list.map((data, idx) => {
                  return (
                    <Banner
                      key={idx}
                      backgroundImage={`url("${data.backgroundCarousel}")`}
                      className="relative"
                    >
                      {viewport.isMobile && booksBuildKeys[idx] && (
                        <div
                          className="absolute"
                          style={{
                            width: '100%',
                            height: '5px',
                            top: 20,
                            left: 'calc(50% - 67px)',
                            zIndex: 20
                          }}
                        >
                          <ThreeDBook
                            bookName={booksBuildKeys[idx].name}
                            page={booksBuildKeys[idx].cover}
                            character={characterPreview}
                            spreadPageSize={booksBuildKeys[idx].spreadPageSize}
                          />
                        </div>
                      )}
                      <ExploreOurStoriesCard
                        data={data}
                        setRedirect={this.props.setRedirect}
                        setShowCharacterBuilderModal={
                          this.props.setShowCharacterBuilderModal
                        }
                        bookKey={booksBuildKeys[idx]}
                      />
                    </Banner>
                  );
                })}
              </Carousel>
            </div>
            <div className="absolute w-full block top-bottom-carousel-1 lg:top-center-carousel-1 h-0">
              {' '}
              <div className="w-30 lg:w-11/12 mx-auto">
                <Controls onPrev={this.prev} onNext={this.next} />
              </div>
            </div>
          </div>
        );
      }
    }

    return <ExternalControlledCarousel {...propsComponent} />;
  };

export default ExploreOurStoriesCarousel;
