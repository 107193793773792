import React from 'react';
import cx from 'classnames';

export type BannerProps = {
  height?: number;
  backgroundImage?: string;
  className?: string;
  bannerVerticalPosition?: string;
  backgroundColor?: string;
  style?: any;
};

const Banner: React.FC<BannerProps> = ({
  children,
  height = 741,
  backgroundImage = 'url("/images/banner/kst-background.png")',
  className,
  bannerVerticalPosition,
  backgroundColor,
  style,
  ...props
}) => {
  const bannerPosition = bannerVerticalPosition || 'bg-center';

  return (
    <div
      className={cx(
        'w-auto bg-primary-lighter bg-no-repeat bg-cover flex flex-col items-center',
        bannerPosition
      )}
      style={{
        ...style,
        height,
        backgroundImage
      }}
      {...props}
    >
      <div
        className={cx('h-full w-full', className)}
        style={{
          backgroundColor
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Banner;
