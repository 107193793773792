import { Character, Cover as CoverType, Size } from '@/services/models';
import Image from 'next/image';
import React from 'react';
import cx from 'classnames';
import Cover from '../BookPreview/Cover';

import styles from './ThreeDBook.module.css';

type Props = {
  bookName: string;
  page: CoverType;
  character: Character;
  spreadPageSize: Size;
};

const ThreeDBook: React.FC<Props> = ({
  bookName,
  page,
  character,
  spreadPageSize
}) => {
  return (
    <div className="w-auto h-auto relative">
      {/* <div className="absolute top-0 left-0 w-160 h-174">
        <Image src="/images/home/3dbook/3dbook.png" width={160} height={174} />
      </div> */}
      <div className={cx('absolute', styles.cover)}>
        <Cover
          bookName={bookName}
          page={page}
          character={character}
          side="right"
          spreadPageSize={spreadPageSize}
          showFlippedCorner={false}
        />
      </div>
    </div>
  );
};

export default ThreeDBook;
