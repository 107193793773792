import React from 'react';
import cx from 'classnames';

import Typography from '@/components/shared/Typography';

import Button from '@/components/shared/Button';
import { BookData } from '@/services/data/book';
import { RootStateOrAny, useSelector } from 'react-redux';

import router from 'next/router';
import ThreeDBook from '@/components/shared/ThreeDBook';
import { BookStructure } from '@/services/models';
import { useViewport } from '@/hooks/useViewport';

export type ExploreOurStoriesCardProps = {
  className?: string;
  data: BookData;
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  setShowCharacterBuilderModal: React.Dispatch<React.SetStateAction<boolean>>;
  bookKey: BookStructure;
};

const ExploreOurStoriesCard: React.FC<ExploreOurStoriesCardProps> = ({
  className,
  data,
  setRedirect,
  setShowCharacterBuilderModal,
  bookKey
}) => {
  const { characterConfigured, characterPreview } = useSelector(
    (state: RootStateOrAny) => state
  );

  const { isDesktop } = useViewport();

  return (
    <div
      className={cx(
        'flex flex-col m-auto rounded-3xl bg-white shadow-card lg:flex-row w-78 lg:w-630 lg:absolute lg:top-36 mt-36 lg:mt-auto',
        className,
        data.carouselOrientation === 'left' ? 'lg:left-1/8' : 'lg:right-1/8'
      )}
    >
      {isDesktop && (
        <div className="absolute" style={{ top: -120, left: 30 }}>
          <ThreeDBook
            bookName={bookKey.keyName}
            page={bookKey.cover}
            character={characterPreview}
            spreadPageSize={bookKey.spreadPageSize}
          />
        </div>
      )}
      <div className="flex flex-col p-6 pt-8 lg:py-11 lg:pr-16 lg:pl-8">
        <Typography
          className="mb-3 text-center lg:text-left text-primary uppercase font-sans font-extrabold lg:mb-6"
          type="14"
        >
          <div style={{ color: 'rgba(107, 188, 255, var(--tw-text-opacity))' }}>
            Explore Our Stories
          </div>
        </Typography>
        <Typography
          className="mb-3 text-center lg:text-left lg:mb-2 font-serif"
          type="mh1"
          style={{ fontFamily: 'Libre Baskerville' }}
        >
          {data.title}
        </Typography>
        <Typography
          className="mb-16 text-center lg:text-left xl:mb-12"
          type="b2"
        >
          {data.descriptionCarousel}
        </Typography>
        <Button
          className="lg:w-44 h-12 zIndex: 20"
          onClick={() => {
            /* if (characterConfigured) {
              router.push(`/book-details/${data.keyName}`);
            } else {
              setShowCharacterBuilderModal(true);
              setRedirect(`/book-details/${data.keyName}`);
            } */
            router.push(`/book-details/${data.keyName}`);
          }}
          aria-label="Jump In Now!"
        >
          <div style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 800 }}>
            Jump In Now!
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ExploreOurStoriesCard;
