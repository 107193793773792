import { StepIllustrationProps } from '.';

const stepDataList: StepIllustrationProps['data'][] = [
  {
    step: 1,
    title: 'Start the process',
    description:
      'You can upload a photo, take a new one, or get started without one',
    illustration: {
      src: '/images/home/steps/upload-photo.jpg',
      width: 180,
      height: 209,
      alt: 'Upload Photo'
    }
  },
  {
    step: 2,
    title: 'Personalize for your child',
    description:
      'Tada! Check out the beautiful illustration of your child and make any desired adjustments',
    illustration: {
      src: '/images/home/steps/illustration.jpeg',
      width: 120,
      height: 242,
      alt: 'See your illustration'
    }
  },
  {
    step: 3,
    title: 'Select your stories',
    description:
      'Preview and choose the stories that your child will love the most',
    illustration: {
      src: '/images/home/steps/select-stories.jpg',
      width: 180,
      height: 180,
      alt: 'Select your stories'
    }
  }
];

export default stepDataList;
